import React, { Component } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import MainHeading from '../../MainHeading/MainHeading';
import leftimg from '../../../assets/images/ourVision.jpg'

class LeftTextRightImg extends Component {
    render() { 
        return (
            <React.Fragment>
                <Container className='section-padding strategy-section '>
                    <Row className='justify-content-center text-center pt-5'>
                        <Col xl={7} className="pb-md-5 pb-3">
                            <MainHeading secHeadClass="fw-bolder pt-4 our-strategy"  secHeadText="Raise the bar with quality web design & development "/>
                        </Col>
                    </Row>
                    <Row className='justify-content-center text-md-start text-center align-items-center mt-4 mt-md-0'>
                        <Col lg={5} md={6} data-aos="fade-right">
                            <div className='content p-md-3'>
                            <h3 className='fw-bold'>Our Vision</h3>
                                <p>We aim to enable businesses to stand higher from the rest of the world. To build a responsive and user oriented quality web designing & web development is our foremost capability to assist in the growth of any business.</p>
                            </div>
                        </Col> 
                        <Col lg={5} md={6} className="ps-md-0" data-aos="fade-left">
                            <div className='content'>
                            <Image src={leftimg} alt="Our Vision" className='w-100 height-img'/>
                            </div>
                        </Col>               
                    </Row>
                </Container>
            </React.Fragment>
);
}
}
export default LeftTextRightImg;