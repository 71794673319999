import React from 'react';
import './SubHeading.css'
const SubHeading = (props)=> {
    return ( 
        <React.Fragment>
                <h5 className={`sub-heading ${props.subClass}`}>{props.subtxt}</h5>
        </React.Fragment>
     );
}

export default SubHeading;