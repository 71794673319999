import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF , faTwitter,faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';

function SocialIcon(props) {

        return ( 
            <React.Fragment>
                <div className="d-flex mb-2 mt-4">
                    { props.fbLink ? <a href={props.fbLink} ><FontAwesomeIcon className="mx-3 text-white h5" icon={faFacebookF} /></a> : null}
                    { props.TwitterLink ? <a href={props.TwitterLink}><FontAwesomeIcon className="mx-3 text-white h5 " icon={faTwitter} /></a> : null}
                    { props.InstagramLink ? <a href={props.InstagramLink}><FontAwesomeIcon className="mx-3 text-white h5 " icon={faInstagram} /></a> : null}
                    { props.linkdlnLink ? <a href={props.linkdlnLink}><FontAwesomeIcon className="mx-3 text-white h5 " icon={faLinkedinIn} /></a> : null}
                </div>           
            </React.Fragment>          
        );
    }
 
export default SocialIcon;