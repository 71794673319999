import React from "react";
import "./Button.css";

const Button = ({ btn_text, type, btnId, btnclassName, btnlink ,onClick,btnhref }) => {
  if (!btn_text) {
    return null;
  }
  return (
    <React.Fragment>
      {type && type === "button" ? (
        <button
        type={type}
        onClick={onClick}
          id={btnId ? btnId : null}
          className={`sitebtn ${btnclassName ? btnclassName : ""}`}
        >
          {btn_text}{" "}
        </button>
      ) : (
        <a
          href={btnlink}
          id={btnId ? btnId : null}
          className={`sitebtn ${btnclassName ? btnclassName : ""}`}
        >
          {btn_text}{" "}
        </a>
      )}
    </React.Fragment>
  );
};
export default Button;
