import React from 'react';
import ReviewCard from '../ReviewCard/ReviewCard';
import UserImg from '../UserImg/UserImg';
import './ClientReview.css'
class ClientReview extends React.Component {
    render() { 
        return (
            <React.Fragment>
                        <UserImg image={this.props.details.image} id={this.props.details.id} alt={this.props.details.author}/> 
                        <ReviewCard title={this.props.details.title} name={this.props.details.author} text={this.props.details.text} id={this.props.details.id}/>
                   
            </React.Fragment>
        );
    }
}
 
export default ClientReview;