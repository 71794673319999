import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import RecentBlog from "../../Components/RecentBlog/RecentBlog";
import { getAllBlogs } from "../../services/contact";
import Loader from "../../Components/Loader/Loader";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    callBack();
  }, []);
  const callBack = () => {
    setLoader(true);
    getAllBlogs()
      .then((response) => {
        const { status, data } = response.data.data;
        if (status === 200) {
          setBlogs(data);
          setLoader(false);

        }
      })
      .catch(() => {
        console.log("Error")
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Latest Web Development Blogs You Should Know About - Suave Creators</title>
        <meta name='keywords' content='Web development services, web developments blogs' />
        <meta name='description' content='Read trendy blogs about web development services & about the technology used while doing programming. Check out blog for more updates about future tech & innovation' />



        <meta
          property="og:title"
          content="Latest Web Development Blogs You Should Know About - Suave Creators"
        />
        <meta
          property="og:image"
          content="https://www.suavecreators.com/static/media/Banner-img.447d83b18cdec70a4344.png"
        />
        <meta property="og:url" content="https://www.suavecreators.com/" />
        <meta
          property="og:description"
          content="Read trendy blogs about web development services & about the technology used while doing programming. Check out blog for more updates about future tech & innovation"
        />
        <meta property="og:site_name" content="Suave creators" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      {!loader ? <RecentBlog blogs={blogs} /> : <Loader />}
    </React.Fragment>
  );
}

