import React from "react";
import { Switch, Route,Redirect } from "react-router-dom";

import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import OurExperties from "./Pages/OurExperties/OurExperties";
import Blog from "./Pages/Blog/Blog";
import SingleBlogPage from "./Pages/SingleBlogPage/SingleBlogPage";
import Contact from "./Pages/Contact/Contact";

export default function Routes() {
  return (
    <Switch>

      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/about-us">
        <About />
      </Route>
      <Route path="/our-expertise">
        <OurExperties />
      </Route>
      {/* <Route path="/Career">
        <Career />
      </Route> */}
      <Route path="/blogs">
        <Blog />
      </Route>
      <Route path="/blog/:post_name">
        <SingleBlogPage />
      </Route>
      <Route path="/contact-us">
        <Contact />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}
