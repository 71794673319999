import androidicon from '../../assets/images/Android.png'
import phpIcon from '../../assets/images/PHP.png'
import htmlIcon from '../../assets/images/HTML.png'
import reactIcon from '../../assets/images/React.png'
import jsIcon from '../../assets/images/JS.png'
import laravelIcon from '../../assets/images/Laravel.png'
import angularIcon from '../../assets/images/Angular.png'
import wordpress from '../../assets/images/wordpress.png'
import Codeigniter from '../../assets/images/codeigniter.png'

const ExpertiseData = [
    
    {
        "image": laravelIcon,
        "alt" : "Laravel",
        "title": "Laravel",
        "text": "We have an experienced and dedicated team of Laravel web developers having immense knowledge developing websites by using the Laravel languages and to deliver requirement-based results. Let’s design your responsive website in the Laravel framework and enjoy the creative experience."
    },
    {
        "image": phpIcon,
        "alt" : "PHP",
        "title": "PHP",
        "text": "Get your Php websites designed and developed by us. We have a complete team of developers who will work on developing websites. Php is one of the most favorable programming languages as it offers the best compatibility with all other web technologies."
    }, 
    {
        "image": htmlIcon,
        "alt" : "HTML",
        "title": "HTML",
        "text": "HTML is one of the most ancient languages in which people want their website. These websites are easy to build. In case, you are booking out for HTML web developer, you can consider Suave Creators - responsive web design company for your business. "
    }, 
    {
        "image": jsIcon,
        "alt" : "Javascript",
        "title": "Javascript",
        "text": "Get your web page customized by our full-stack developer in JavaScript. JavaScript is one of the most used scripting languages to develop a website and make it fully-functional. We believe in offering user-friendly solutions which always help in businesses. Choose our developers for your next project. "
    },
    {
        "image": reactIcon,
        "alt" : "React",
        "title": "React",
        "text": "The demand for React is increasing day-by-day. It is one of the most in-demand and latest languages. We have a proficient frontend development team to design & develop your website in React.js.  Our major priority is delivering perfect React development services for all of our global working clients."
    },
    {
        "image": angularIcon,
        "alt" : "Angular",
        "title": "Angular",
        "text": "Angular is one of the recent programming languages which is widely used nowadays. Our programming experts can develop website in Angular using various versions of Angular i.e. Angular 2, Angular 4, Angular 6 etc. "
    },
    {
        "image": wordpress,
        "alt" : "WordPress",
        "title": "WordPress",
        "text": "We have a team of proficient Wordpress Developers from India. Our expert Wordpress developers help clients to develop purely reliable and scalable WordPress websites by using  at the best market prices and within a short time. Try a user-friendly platform to make your website."
    }, 
     {
        "image": Codeigniter,
        "alt" : "Codeigniters",
        "title": "Codeigniters",
        "text": "Being one of the best CodeIgniter developers we offer quality and innovative web solutions in CodeIgniter PHP Framework. Our developers create fully functional websites and apps. All of our developers are highly skilled to work on all the latest languages."
    },
    {
        "image": androidicon,
        "alt" : "Android",
        "title": "Android",
        "text": "Android’s market is very huge and people focus on getting applications based on Android. We have expert android developers at Suave Creators who can customize the applications according to the requirements. "
    },
]
export default ExpertiseData;