import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import bannerImg from '../../assets/images/Banner-img.png';
import './ContactBanner.css'
import BannerHeading from '../BannerHeading/BannerHeading';
import Button from '../Button/Button';
import ContactDetail from '../ContactDetail/ContactDetail';
import SocialIcon from '../SocialIcon/SocialIcon';
import { Link } from 'react-router-dom';

function ContactBanner() {
    
  return (
   <React.Fragment>
        <section id='contact-banner'>
            <Container fluid className="h-100">
                <Row className=' align-items-center h-100'>
                    <Col lg={7} className='contactleft-side p-5 h-100 d-flex align-items-baseline justify-content-center flex-column'>
                            <BannerHeading bannerHead="Contact Us" bannerClass="text-white"/>
                            <p className='text-content my-3 my-sm-4 text-mute w-75'>Online Businesses do really great when people know about them. We intend to design & develop a website for you at Suave Creators.</p>
                            <Link to={"/contact-us"}><Button  btnclassName="grad-bg me-3 mt-3" btn_text="Send a Message" btnlink='#form-sec'/></Link>
                    </Col>
                    <Col  lg={5} data-aos="fade-up" data-aos-duration="3000" className='contactRight-side h-100'>
                        <h3 className="fw-bold">Contact Info</h3>
                        <ContactDetail address="Palampur, Kangra, Himachal Pradesh-176085" officeTiming={["Mon-Fri : 9:30 - 6:30",<br/> ,"Sat-Sun: Closed"]} mailInfo="aakash@suavecreators.com" phoneInfo="+917018355389" />
                        <SocialIcon linkdlnLink="https://www.linkedin.com/company/suave-creators/" fbLink="https://www.facebook.com/suavecreators"/>
                    </Col>
                </Row>
            </Container>
        </section>
   </React.Fragment>
  );
}

export default ContactBanner;
