import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftTextRightImg from "../../Components/AboutComponents/LeftTextRightImg/LeftTextRightImg";
import CallToAction from "../../Components/CallToAction/CallToAction";
import Testimonial from "../../Components/Testimonial/Testimonial";
import "./About.css";
import LefttoRight from "../../Components/AboutComponents/LefttoRight/LefttoRight";
import RightTextLeftImg from "../../Components/AboutComponents/RightTextleftImg/RightTextleftImg";
import Whysc from "../../Components/Whysc/Whysc";
import JoinTeam from "../../Components/JoinTeam/JoinTeam";
import { Helmet } from "react-helmet";
class About extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            About Suave Creators Company - A Creative Web Development Team
          </title>
          <meta name="keywords" content="Web development , quality website" />
          <meta
            name="description"
            content="We focus on developing a quality website that can raise the standard of your business. Suave Creators make development process more innovative for more dynamic output"
          />

          <meta
            property="og:title"
            content="About Suave Creators Company - A Creative Web Development Team"
          />
          <meta
            property="og:image"
            content="https://www.suavecreators.com/static/media/Banner-img.447d83b18cdec70a4344.png"
          />
          <meta property="og:url" content="https://www.suavecreators.com/" />
          <meta
            property="og:description"
            content="We focus on developing a quality website that can raise the standard of your business. Suave Creators make development process more innovative for more dynamic output"
          />
          <meta property="og:site_name" content="Suave creators" />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <main className="overflow-hidden">
          <section className="aboutUS">
            <div className="about-overlay h-100"></div>
            <Container>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="content text-center text-white">
                    {/* <h1 className='fw-bold'>About US</h1> */}
                    <p>
                      Suavecreators is a team of creative minds who are into
                      complete web development services. At Suave creators, we
                      ensure that the growth aspects are driven towards the
                      company, clients & the team to provide improved work-life
                      culture within the industry.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="allstrategies">
            <LeftTextRightImg />
            <RightTextLeftImg />
            <LefttoRight />
          </section>
          <Whysc />
          <JoinTeam />
          <Testimonial />
          <CallToAction />
        </main>
      </React.Fragment>
    );
  }
}
export default About;
