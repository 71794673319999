import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SubHeading from "../SubHeading/SubHeading";
import MainHeading from "../MainHeading/MainHeading";
import "./Testimonial.css";
import ReviewData from "../ReviewData/ReviewData";
import ClientReview from "../ClientReview/ClientReview";
import centeraLogo from "../../assets/images/Review-center-logo.png";
import centerbg from "../../assets/images/bg-circle.png";

class Testimonial extends Component {
  constructor() {
    super();
    this.state = {
      posts: {},
    };
  }
  componentWillMount() {
    this.setState({
      posts: ReviewData,
    });
  }
  render() {
    return (
      <React.Fragment>
        <section id="testimonial">
          <Container>
            <Row>
              <Col sm={12} className="text-center mb-2">
                <SubHeading subClass=" linebar" subtxt="Our Work Speaks! " />
                <MainHeading
                  secHeadClass="fw-bolder "
                  secHeadText="Check out what clients have to say about us. "
                />

                <div className="client-review">
                  <div className="center-logo">
                    <img src={centeraLogo} className="main-logo" alt="Logo" />
                  </div>
                  <img src={centerbg} className="circle-bg" alt="cirlce bg" />
                  {Object.keys(this.state.posts).map((key) => (
                    <ClientReview
                      key={key}
                      index={key}
                      details={this.state.posts[key]}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonial;
