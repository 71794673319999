import React, { useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Logo from "../Logo/Logo";
import Button from "../Button/Button.js";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  useEffect(() => {
  }, []);

  const activeClassName = (url) => {
    return window.location.pathname === url;
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link
              href="/"
              className={`${activeClassName("/") ? "active" : ""}`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={`${activeClassName("/about-us") ? "active" : ""}`}
              href="/about-us"
            >
              About
            </Nav.Link>
            <Nav.Link
              className={`${activeClassName("/our-expertise") ? "active" : ""}`}
              href="/our-expertise"
            >
              Our Expertise
            </Nav.Link>
            <Nav.Link
              className={`${activeClassName("/blogs") ? "active" : ""}`}
              href="/blogs"
            >
              Blogs
            </Nav.Link>
          </Nav>
          <Link to={"/contact-us"}>
            <Button btnclassName="grad-bg text-white" btn_text="Contact Us" />
          </Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
