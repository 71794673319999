import React from 'react';
import { Container, Row,Col } from 'react-bootstrap';
import Expertiesimg from '../../assets/images/expertise.jpg'
import BannerHeading from '../BannerHeading/BannerHeading';
import './ExpertiseBanner.css'

function ExpertiseBanner() {
    return (
        <React.Fragment>
             <section className='expertise_banner'>
                <Container fluid>
                    <Row>
                        <Col>

                        <Row className='align-items-center h-100'>
                        <Col md={5} className='h-100 d-md-block d-none p-0'>
                            <img src={Expertiesimg} alt="Our Expertise in web development" className="w-100 " />
                        </Col>
                        <Col md={7} className="h-100 p-0">
                            <div className='content expertise-right p-md-0 p-2  align-items-center h-100 d-flex'>
                            <div className='content-info ps-md-5 pt-md-0 pb-md-0 pt-5 pb-5 text-md-start text-center  mx-auto'>
                                <BannerHeading bannerHead="Our Expertise" bannerClass="display-4 fw-bold text-white "/>
                            <p className='fw-normal h5 text-light'>Our team can provide a wide range of mobile, web, desktop and enterprise software technology expertise across all major development environments, frameworks and platforms.</p>
                            </div>
                            </div>
                        </Col>
                      
                        </Row>

                        </Col>
                    </Row>
                </Container>
                
             </section>
        </React.Fragment>


);
}
export default ExpertiseBanner;