import React from 'react';
import './BannerHeading.css'

const BannerHeading = (props) => {
    return (
        <React.Fragment>
            <h1 className={`banner-heading fw-bold ${props.bannerClass}`}>{props.bannerHead}</h1>
        </React.Fragment>
      );
      
      
}

export default BannerHeading;