import React from 'react';
import './ContactDetail.css'
import Map from "../../assets/images/pin.png"
import clock from "../../assets/images/clock.png"
import chat from "../../assets/images/chat.png"

function ContactDetail(props) {
    return ( 
        <React.Fragment>
            <ul className='list-unstyled mb-0 contact-list'>
                <li className='d-flex mt-3'>
                    <img src={Map} alt="location" className="contact-icon"/>
                    <div className='ms-3'>
                        <h5>Our Office</h5>
                        <address>{props.address}</address>
                    </div>
                </li>
                <li className='d-flex mt-3'>
                    <img src={clock} alt="clock" className="contact-icon"/>
                    <div className='ms-3'>
                        <h5>Open Office Hour</h5>
                        <p>{props.officeTiming}</p>
                    </div>
                </li>
                <li className='d-flex mt-3'>
                    <img src={chat} alt="chat" className="contact-icon"/>
                    <div className='ms-3'>
                        <h5>Get in Touch</h5>
                        <a href={"mailto:" + props.mailInfo} className="d-block text-dark" >{props.mailInfo}</a>
                        <a href={"tel:" + props.phoneInfo } className="d-block text-dark">{props.phoneInfo}</a>
                    </div>
                </li>
            </ul>
        </React.Fragment>
     );
}

export default ContactDetail;