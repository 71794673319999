import API from "../api/api";

const contactFormService = async (body) => {
  const response = await API.post(`save-leads`, body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
const getAllBlogs = async () => {
  const response = await API.get(`get-all-blogs`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

const getSingleBlog = async (id) => {
  const response = await API.get(`get-blog-detail/${id}`)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export { contactFormService ,getAllBlogs ,getSingleBlog};
