import React from 'react';
import { Image } from 'react-bootstrap';
import './CardHeader.css'
class CardHeader extends React.Component {
    render() {
      const { image, number, cardImgClass, alt } = this.props;
      
    
      return (
          <div className="card-header bg-transparent border-0 p-0">
                <Image type="image" src= {image} className={cardImgClass} alt={alt}/>
                <h6>{number}</h6>
        </div>
      )
    }
  }
  export default CardHeader;