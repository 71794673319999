import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import SubHeading from '../SubHeading/SubHeading';
import bannerImg from '../../assets/images/Banner-img.png';
import './Banner.css'
import BannerHeading from '../BannerHeading/BannerHeading';
import Button from '../Button/Button';
function Banner() {
    
  return (
   <React.Fragment>
        <section id='banner'>
            <Container className='h-100'>
                <Row className='h-100 align-items-center'>
                    <Col lg={6} className='text-md-start text-center' data-aos="fade-down" data-aos-duration="1500">
                        <SubHeading subClass="sub-heading mb-3" subtxt="Creativity Persists For Longer Duration"/>
                        <BannerHeading bannerHead={["Bring your Business Online  We are a fast, reliable, responsive & Creative Team"]}/>
                        <p className='text-content my-3 mt-sm-4 mb-sm-5'>Online Businesses do really great when people know about them. We intend to design & develop a website for you at Suave Creators.</p>
                        <Button btnclassName="grad-bg me-3 mt-3" btn_text="Our Services" btnlink={"/our-expertise"}/>
                        <Button btnclassName="blue-bg mt-3" btn_text="Contact Us" btnlink={"/contact-us"}/>
                    </Col>
                    <Col  lg={6} data-aos="fade-up"data-aos-duration="3000">
                        <Image src={bannerImg} alt="Suave Creators - Web Development Company" className='w-100 mt-5 mt-xl-0'/>
                    </Col>
                </Row>
            </Container>
        </section>
   </React.Fragment>
  );
}

export default Banner;
