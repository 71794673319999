import React from 'react';
import {Accordion} from 'react-bootstrap';
import './Faq.css'
function Faq() {
    return ( 
        <>
           <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Will my website work on smartphones and tablets as well?</Accordion.Header>
                    <Accordion.Body>
                    Yes, of course we create responsive websites so that you can easily open all websites on smartphones and tablets as well. 

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How to make the website more engaging?</Accordion.Header>
                    <Accordion.Body>
                    There are lots of things that make a website more engaging. We have a team of developers who will create your website more attractive and engaging in terms of design, colors, content and responsiveness etc. 

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>What is the best payment gateway for an eCommerce website?</Accordion.Header>
                    <Accordion.Body>
                    There are lots of payment gateways that are being used nowadays. But Paypal is the best and trusted ever and the others which you can use are Stripe, razorpay, wepay and other debit, credit cards etc. 


                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
     );
}

export default Faq;
