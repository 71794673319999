import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogCard from "../BlogCard/BlogCard";
// import BlogData from "../BlogData/BlogData";
import "./RecentBlog.css";

export default function RecentBlog({ blogs = [] }) {
  const [posts, setposts] = useState(blogs);

  useEffect(() => {
    setposts(blogs);
  }, [blogs]);
  return (
    <>
      <section className="py-5">
        <Container>
          <Row>
            {posts.map((post, key) => (
              <Col md={4} className="remove-margin"  key={key}>
                {" "}
                <BlogCard
                  key={key}
                  index={key}
                  details={post}
                  cardClass="blog-cards"
                />{" "}
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
