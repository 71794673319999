import React from 'react';
import SubHeading from '../SubHeading/SubHeading';
import research from '../../assets/images/research.png'
import delivery from '../../assets/images/fast-delivery.png'
import process from '../../assets/images/project-management.png'
function Whysc() {
    return (
        <>
            <section className='py-5 position-relative'>
                <div className='container py-5'>
                    <div className='row justify-content-evenly'>
                        <div className='col-md-11 col-xl-9 text-center  '>
                                <SubHeading subClass="linebar" subtxt="Suave Creators "/>
                                <h1 className='fw-bold'>Why Suave Creators</h1>
                                <p className='about_para_content'>We are one of the reputed website development companies where we focus on giving cent percent to the client’s requirement.</p>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-4 mt-md-5'>
                            <div className="purple-box p-4 d-xl-flex align-items-start">
                                <img src={process} className="steps-icon" alt="Innovative & Engaging Process"/>
                                <div className="about-inner-content mt-3 mt-xl-0 pe-0 ps-xl-4">
                                <h5 className='fw-bold'>Innovative & Engaging Process</h5>
                                <p>We believe in bringing engagement through the creative efforts at our workplace. Our strategies are uniquely delivered to the clients, which keeps them at bay to converge businesses into better opportunities. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-5'>
                            <div className="cream-box p-4 d-xl-flex align-items-start">
                                <img src={research} className="steps-icon" alt="Research driven results"/>
                                <div className="about-inner-conten mt-3 mt-xl-0 pe-0 ps-xl-4">
                                <h5 className='fw-bold'>Research driven results</h5>
                                <p>Suave creators always focus on research before proceeding with any project. This helps us in preparing our comprehensive strategy, which results in the brand’s success with relentless growth. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-5'>
                            <div className="orange-box p-4 d-xl-flex align-items-start">
                                <img src={delivery} className="steps-icon" alt="Optimal Delivery"/>
                                <div className="about-inner-content mt-3 mt-xl-0 pe-0 ps-xl-4">
                                <h5 className='fw-bold'>Optimal Delivery</h5>
                                <p>We don’t just deliver the services, but ensure that our clients are happy with what we are delivering to them. Our approach and strategies mark the excellence in our efforts to provide them with better deliverables.  </p>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
      );
}

export default Whysc;