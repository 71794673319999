import React from 'react';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';

function JoinTeam() {
    return (
        <>
            <section className='join-team'>
                <div className="join-team-overlay"></div>
                <div className="container">
                    <div className="join-team-content">
                        <h2>HEY AGENTS CAREERS</h2>
                      <Link to={"/contact-us"}>  <Button  btnclassName=" mt-md-4 mt-3 white-bg "  btn_text="Join Our Team" /></Link>
                    </div>
                </div>
            </section>
        </>
      );
}

export default JoinTeam;