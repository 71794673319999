import React from "react";
import { Link } from "react-router-dom";

export default function BlogCardBody(props) {
  return (
    <>
      <div className="card-body">
        <h6 className="meta-data">{props.name}</h6>
        <h5 className="my-4">{props.title}</h5>
        <p
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
        <Link
          to={{ pathname: `/blog/${props.post_name}`, state: { id: props.ID } }}
          className="learn-more sitebtn"
        >
          Learn More
        </Link>
      </div>
    </>
  );
}
