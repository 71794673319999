import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import footerLogo from '../../assets/images/Logo-white.png';
import './Footer.css'
class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <div className='footer py-5 text-start'>
                    <Container>
                        <Row>
                            <Col lg={4} md={6} className="my-lg-4 my-2">
                                <img src= {footerLogo}  alt="ggfh"/>
                                <p className='my-4'>We are an innovative web design & development company. Our major insights are to provide quality work and grow your business in the best possible way. </p>

                            </Col>
                            <Col lg={3} sm={6} className="my-lg-4 my-2">
                                <div className='footer-links text-white ps-lg-5'>
                                    <h5 className='mb-4'>Important Links</h5>
                                    <ul className='list-unstyled m-0'>
                                        <li className='mb-3'><a href="/our-expertise">Services</a></li>
                                        <li className='mb-3'><a href="/about-us">About us</a></li>
                                        {/* <li className='mb-3'><a href="/Blog">Blog</a></li> */}
                                        {/* <li className='mb-3'><a href="/Contact#form-sec">FAQs</a></li> */}
                                        <li className='mb-3'><a href="/contact-us">Contact</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={2} sm={6} className="my-lg-4 my-2">
                                <div className='footer-links text-white'>
                                    <h5 className='mb-4'>Services</h5>
                                    <ul className='list-unstyled m-0'>
                                        <li className='mb-3'><a href="/our-expertise">Laravel</a></li>
                                        <li className='mb-3'><a href="/our-expertise">Codeigniter</a></li>
                                        <li className='mb-3'><a href="/our-expertise">PHP</a></li>
                                        <li className='mb-3'><a href="/our-expertise">Wordpress</a></li>
                                        <li className='mb-3'><a href="/our-expertise">Angular</a></li>
                                        <li className='mb-3'><a href="/our-expertise">React</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={3} md={6} className="my-lg-4 my-2">
                                <div className='footer-links text-white'>
                                    <h5 className='mb-4'>Keep In Touch</h5>
                                    <address className='mb-3'>Address:<br/>Palampur,<br/> Kangra,<br/> Himachal Pradesh-176085</address>
                                    <p className='mb-3'>Phone:<br/>+917018355389 / +919736900142</p>
                                    <p>Email address:<br/>aakash@suavecreators.com</p>
                                </div>
                            </Col>
                            <Col lg={12} className="mt-5 copy-rt">
                               <p className='text-center pt-3'>2022 - All rights reserved for SuaveCreators</p>
                            </Col>
                        </Row>

                    </Container>
                </div>

            </React.Fragment>
        );
    }
}
 
export default Footer;