import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Root = <App />;
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Root, rootElement);
} else {
  render(Root, rootElement);
}
// ReactDOM.render(<App />, document.getElementById('root'));
