import React, { useState } from "react";
import MainHeading from "../MainHeading/MainHeading.js";
import { Form } from "react-bootstrap";
import Button from "../Button/Button.js";

//services
import { contactFormService } from "../../services/contact.js";
function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [textArea, setTextArea] = useState("");
  const [message, setMessage] = useState("");

  const changeHandler = (e) => {
    if (e.target.name === "fullname") {
      setName(e.target.value);
    }
    if (e.target.name === "emailaddress") {
      setEmail(e.target.value);
    }
    if (e.target.name === "message") {
      setTextArea(e.target.value);
    }
  };
  const resetHandler = () => {
    setName("");
    setEmail("");
    setTextArea("");
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let body = {
      name: name,
      email: email,
      message: textArea,
      phone:null,
    };
    contactFormService(body)
      .then((response) => {
        const { status } = response.data.data;
        if (status === 200) {
          resetHandler();
          setMessage("Message Send Succesfully");
          setTimeout(() => {
            setMessage("");
          }, 8000);
        }
      })
      .catch(() => {});
  };
  return (
    <React.Fragment>
      <div className="form-Left">
        <MainHeading secHeadText="Get in Touch" secHeadClass="fw-bold" />
        <span className="green"> {message}</span>
        <Form method="Get" className="cont-form mt-4 mb-4 mb-lg-0">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Control
              type="text"
              onChange={changeHandler}
              value={name}
              name="fullname"
              placeholder="Full Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              onChange={changeHandler}
              type="email"
              value={email}
              name="emailaddress"
              placeholder="Email address"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              onChange={changeHandler}
              as="textarea"
              name="message"
              value={textArea}
              rows={5}
              placeholder="Message"
            />
          </Form.Group>
          <Button
            type="button"
            onClick={submitHandler}
            btn_text="Send a Message"
            btnclassName="grad-bg border-0"
          />
        </Form>
      </div>
    </React.Fragment>
  );
}

export default ContactForm;
