import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubHeading from '../SubHeading/SubHeading';
import MainHeading from '../MainHeading/MainHeading';
import ExpertiseData from '../ExpertiseData/ExpertiseData'
import SecCard from '../SecCard/SecCard'
import './TechnicalExpertise.css'
import Button from '../Button/Button';

class TecnicalExpertise extends Component {
    constructor() {
        super();
        
        this.state = {
          posts: {}
        }
      }
      componentWillMount() {
        this.setState({
          posts: ExpertiseData
        });
      }
    render() { 
        return (
            <React.Fragment>
            <section id='technical-expertise'>
                <Container>
                    <Row>
                        <Col sm={12} className='text-center mb-2'>
                            sw
                            <SubHeading subClass=" linebar" subtxt="Suave Creators"/>
                            <MainHeading secHeadClass="fw-bolder " secHeadText="Our Technical Expertise"/>
                        </Col>
                           
                    </Row>
                    <Row className='row-cols-1 row-cols-sm-2 row-cols-xl-3 justify-content-center'>
                    
                        {
                        Object
                        .keys(this.state.posts)
                        .map(key => <SecCard key={key} index={key} details={this.state.posts[key]} cardClass="expertise-card text-center"/>)
                        }
                        
                    </Row>
                    <Col sm={12} className='text-center mt-5'>
                                <Button btnclassName="grad-bg"  btn_text="View All Services" btnlink="/our-expertise"/>
                    </Col>
                </Container>
            </section>
        </React.Fragment>
        );
    }
}
 
export default TecnicalExpertise;