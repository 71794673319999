const ChooseUsData = [
    {
        "number": "1",
        "title": "We are Proficient",
        "text": "Proficiency does not mean just being professional. We are proficient to understand our client’s requirements, work upon them and take input on each & every minute steps as well to ensure that whatever we are bringing up works out well for them. "
    },
    {
        "number": "2",
        "title": "Experience Does Matter!",
        "text": "We have a team of experienced developers who meet your expectations on another level. Our team works upon to build a responsive website as per your custom definition. "
    }, 
    {
        "number": "3",
        "title": "Fast",
        "text": "Fast does not only define the pace at which we work. We are fast enough to learn new technologies, adapt them and work upon them. Being one of the best web development companies, web designing is an integral part of our work, which we showcase via various innovative attempts. "
    }, 
    {
        "number": "4",
        "title": "Delivery On Date",
        "text": "Punctuality is necessary. We emphasize on “on-time delivery”. Our deliverables depend upon the customer's acquisition and requirements. We ensure to be quick in answering the queries and opting out the best way to create innovative web page design.  "
    }, 
]
export default ChooseUsData;