import React, { Component } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import leftimg from '../../../assets/images/ourMission.jpg'

class RightTextLeftImg extends Component {
    render() { 
        return (
            <React.Fragment>
                <Container className='strategy-section mt-4 mt-md-0'>
                    <Row className='justify-content-center text-md-start text-center align-items-center '>                       
                        <Col lg={5} md={6}   className="pe-md-0 order-2 order-md-1 " data-aos="fade-right">
                            <div className='content'>
                            <Image src={leftimg} alt="Our Mission" className='w-100 height-img'/>
                            </div>
                        </Col> 
                        <Col lg={5} md={6}  data-aos="fade-left" className='order-1 order-md-2 ' >
                            <div className='content  p-md-3 '>
                            <h3 className='fw-bold'>Our Mission: </h3>
                                <p>Our ultimate goal is to provide solutions to our esteemed clients beyond their requirements. We wish to ensure knowledge driven services where they can easily solve the relevant business concerns as per their client’s requirement. </p>
                            </div>
                        </Col>               
                    </Row>
                </Container>
            </React.Fragment>
);
}
}
export default RightTextLeftImg;