import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubHeading from '../SubHeading/SubHeading';
import MainHeading from '../MainHeading/MainHeading';
// import NewsData from '../NewsData/NewsData'
// import SecCard from '../SecCard/SecCard'
import './LatestNews.css'
import Button from '../Button/Button';
import { getAllBlogs } from '../../services/contact';
import BlogCard from '../BlogCard/BlogCard';

const LatestNews = () => {

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    callBack();
  }, []);
  const callBack = () => {
    getAllBlogs()
      .then((response) => {
        const { status, data } = response.data.data;
        if (status === 200) {
          setPosts(data);
        }
      })
      .catch(() => { });
  };

  return (
    <React.Fragment>
      <section id='latest-news'>
        <Container>
          <Row>
            <Col sm={12} className='text-center mb-2'>
              <SubHeading subClass=" linebar" subtxt="Suave Creators" />
              <MainHeading secHeadClass="fw-bolder " secHeadText="Latest Insights on Web Design & Development
Check out our latest blogs "/>
            </Col>
          </Row>
          <Row className='row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-between'>
            {
              posts.slice(0, 3)
                .map((post, key) => <BlogCard key={key} index={key} details={post} cardClass="news-card text-center margin-right-2 w-32" />)
            }
          </Row>
          <Col sm={12} className='text-center mt-5'>
            <Button btnclassName="grad-bg" btn_text="View All Blogs" btnlink='/blogs' />
          </Col>
        </Container>
      </section>
    </React.Fragment>
  )

}
export default LatestNews;