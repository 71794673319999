import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubHeading from '../SubHeading/SubHeading';
import MainHeading from '../MainHeading/MainHeading';
import ChooseUsData from '../ChooseUsData/ChooseUsData'
import SecCard from '../SecCard/SecCard'
import './WhyUs.css'
import Button from '../Button/Button';
class WhyUs extends Component {
    constructor() {
        super();
        
        this.state = {
          posts: {}
        }
      }
      componentWillMount() {
        this.setState({
          posts: ChooseUsData
        });
      }
    render() { 
        return (
            <React.Fragment>
                <section id='choose-us'>
                    <Container>
                        <Row className='align-items-center justify-content-between'>
                            <Col lg={5} className=' position-relative'>
                                <SubHeading subClass=" linebar line-h text-center" subtxt="Suave Creators"/>
                                <MainHeading secHeadClass="fw-bolder text-center " secHeadText="Why Us?"/>
                                <p className='text-content text-center my-3 mt-sm-4 mb-sm-5'>We are one of the reputed website development companies where we focus on giving cent percent to the client’s requirement. Key elements which adds value to our work & working attributes are </p> 
                                  <ul className='text-content'>
                                    <li className='font-weight-bold'>We are proficient</li>
                                    <li className='font-weight-bold'>Experience does matter!</li>
                                    <li className='font-weight-bold'>Fast deliverables</li>
                                    <li className='font-weight-bold'>Punctuality is a must.</li>
                                  </ul>
                                <Button btnclassName="grad-bg mt-3"  btn_text="View All Services" btnlink='/our-expertise'/>
                            </Col>
                            <Col lg={6} className='text-center'>
                            <Row className='row-cols-1 row-cols-md-2 '>
                              {
                                Object
                                .keys(this.state.posts)
                                .map(key => <SecCard key={key} index={key} details={this.state.posts[key]} cardClass="choose-card"/>)
                                }
                                
                            </Row>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
 
export default WhyUs;