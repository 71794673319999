import React from 'react';
import { Helmet } from 'react-helmet';
import CallToAction from '../../Components/CallToAction/CallToAction';
import ExpertiseBanner from '../../Components/ExpertiseBanner/ExpertiseBanner';
import ExpertiseServicesData from '../../Components/ExpertiseServicesData/ExpertiseServicesData';
import Testimonial from '../../Components/Testimonial/Testimonial';

function OurExperties() {
    
        return (
            <React.Fragment>
                 <Helmet>
                    <title>Web Development & Our Core Expertise | Suave Creators</title>
                    <meta name='keywords' content='Web Development Expertise, business platforms' />
                    <meta name='description' content='We have core expertise in Web Development to develop end to end solutions in various platforms for every size of business & domains.Laravel, PHP, React ,Angular..' />
              
                    <meta
            property="og:title"
            content="Web Development & Our Core Expertise | Suave Creators"
          />
          <meta
            property="og:image"
            content="https://www.suavecreators.com/static/media/Banner-img.447d83b18cdec70a4344.png"
          />
          <meta property="og:url" content="https://www.suavecreators.com/" />
          <meta
            property="og:description"
            content="We have core expertise in Web Development to develop end to end solutions in various platforms for every size of business & domains.Laravel, PHP, React ,Angular.."
          />
          <meta property="og:site_name" content="Suave creators" />
          <meta name="robots" content="index,follow" />
                </Helmet>
                <ExpertiseBanner />
                <ExpertiseServicesData/>
                <Testimonial />
                <CallToAction />
                
            </React.Fragment>
               
            );
     
    }
export default OurExperties;