import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ContactBanner from '../../Components/ContactBanner/ContactBanner';
import ContactCTA from '../../Components/ContactCTA/ContactCTA';
import FormSection from '../../Components/FormSection/FormSection';
class Contact extends Component {
  state = {}
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Looking for Web development services ? Contact Us | SuaveCreators</title>
          <meta name='keywords' content='Contact Suave creators, visible business presence' />
          <meta name='description' content='Want to build your website to look for more intent & visible business presence , Contact us today. Suave creators is a brand recognized for its quality & work' />


          <meta
            property="og:title"
            content="Looking for Web development services ? Contact Us | SuaveCreators"
          />
          <meta
            property="og:image"
            content="https://www.suavecreators.com/static/media/Banner-img.447d83b18cdec70a4344.png"
          />
          <meta property="og:url" content="https://www.suavecreators.com/" />
          <meta
            property="og:description"
            content="Want to build your website to look for more intent & visible business presence , Contact us today. Suave creators is a brand recognized for its quality & work"
          />
          <meta property="og:site_name" content="Suave creators" />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <ContactBanner />
        <ContactCTA phNumber="+917018355389" contactSubtxt="We can't wait to make your Idea a reality" />
        <FormSection />
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54066.338989094!2d76.50565557502479!3d32.119354075965035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3904b3e0d1e63ac9%3A0x11046afda32dfd59!2sPalampur%2C%20Himachal%20Pradesh!5e0!3m2!1sen!2sin!4v1647184304752!5m2!1sen!2sin" title='Location Link' width="100%" height="450" loading="lazy"></iframe>

      </React.Fragment>

    );
  }
}
export default Contact;