import React, { Component } from 'react';
// import SiteTabs from '../../Components/SiteTabs/SiteTabs.js';
import Banner from '../../Components/Banner/Banner.js';
import TechnicalExpertise from '../../Components/TechnicalExpertise/TechnicalExpertise.js';
import WhyUs from '../../Components/WhyUs/WhyUs.js';
import LatestNews from '../../Components/LatestNews/LatestNews.js';
import Testimonial from '../../Components/Testimonial/Testimonial.js';
import  CallToAction from '../../Components/CallToAction/CallToAction.js'
import Helmet from 'react-helmet';

class Home extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                 <Helmet>
                    <title>Best Rated Website Development Company - Suave Creators</title>
                    <meta name='keywords' content='Website Development company, responsive website, suave creators' />
                    <meta name='description' content='Suave Creators  intend to develop & design a fully responsive website for your business. Having a professional team make our key elements more productive & valuable' />
              
                    <meta
          property="og:title"
          content="Best Rated Website Development Company - Suave Creators"
        />
        <meta
          property="og:image"
          content="https://www.suavecreators.com/static/media/Banner-img.447d83b18cdec70a4344.png"
        />
        <meta property="og:url" content="https://www.suavecreators.com/" />
        <meta
          property="og:description"
          content="Suave Creators  intend to develop & design a fully responsive website for your business. Having a professional team make our key elements more productive & valuable"
        />
        <meta property="og:site_name" content="Suave creators" />
        <meta name="robots" content="index,follow" />
              
                </Helmet>
                <Banner />
                <TechnicalExpertise />
                {/* <SiteTabs /> */}
                <CallToAction/>
                <WhyUs />
                <Testimonial />
                <LatestNews />
            </React.Fragment>
               
            );
        }
    }
export default Home;