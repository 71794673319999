import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import expertiseLaravel from '../../assets/images/expertiseLaravel.jpg'
import expertiseLaravel1 from '../../assets/images/expertiseLaravel1.jpg'
import './ExpertiseServicesData.css'

function ExpertiseServicesData() {
    return (
        <React.Fragment>
            <section className="mb-5 experties_page_services">
                <Container className="pb-5">
                    <Row className='justify-content-center'>
                        <Col md={9} className="mt-3 mt-md-5 text-center">
                            <h2 className='display-4 fw-bold'>Services We Cover</h2>
                            <p className='text-center'>We have proven our expertise and practical competence to cover end-to-end development processes for various platforms and to deliver result-oriented software solutions for businesses of every size and domain.We provide end to end ecommerce solutions. Whether you are looking for a fully outsourced team or à la carte services, P3 has you covered.​</p>
                        </Col>
                        <Col md={4} className="mt-3 mt-md-5">

                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel} alt="Laravel" className="w-100 shadow" />
                                <h3 className='fw-bold h2'>Laravel</h3>
                                <p>We have an experienced and dedicated team of Laravel web developers having immense knowledge developing websites by using the Laravel languages and to deliver requirement-based results. Let’s design your responsive website in the Laravel framework and enjoy the creative experience.</p>
                            </div>
                        </Col>

                        <Col md={4} className="mt-3 mt-md-5">

                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel1} alt="PHP" className="w-100 shadow" />
                                <h3 className='fw-bold h2'>PHP</h3>
                                <p>Get your Php websites designed and developed by us. We have a complete team of developers who will work on developing websites. Php is one of the most favorable programming languages as it offers the best compatibility with all other web technologies.</p>
                            </div>
                        </Col>
                        <Col md={4} className="mt-3 mt-md-5">

                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel} alt="HTML" className="w-100 shadow" />
                                <h3 className='fw-bold h2'>HTML</h3>
                                <p>HTML is one of the most ancient languages in which people want their website. These websites are easy to build. In case, you are booking out for HTML web developer, you can consider Suave Creators - responsive web design company for your business.</p>
                            </div>
                        </Col>
                        <Col md={4} className="mt-3 mt-md-5">
                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel1} alt="Javascript" className="w-100 shadow" />

                                <h3 className='fw-bold h2'>Javascript</h3>
                                <p>Get your web page customized by our full-stack developer in JavaScript. JavaScript is one of the most used scripting languages to develop a website and make it fully-functional. We believe in offering user-friendly solutions which always help in businesses. Choose our developers for your next project.</p>
                            </div>
                        </Col>

                        <Col md={4} className="mt-3 mt-md-5">
                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel} alt="React" className="w-100 shadow" />

                                <h3 className='fw-bold h2'>React</h3>
                                <p>The demand for React is increasing day-by-day. It is one of the most in-demand and latest languages. We have a proficient frontend development team to design & develop your website in React.js. Our major priority is delivering perfect React development services for all of our global working clients.</p>
                            </div>
                        </Col>
                        <Col md={4} className="mt-3 mt-md-5">
                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel1} alt="Angular" className="w-100 shadow" />

                                <h3 className='fw-bold h2'>Angular</h3>
                                <p>Angular is one of the recent programming languages which is widely used nowadays. Our programming experts can develop website in Angular using various versions of Angular i.e. Angular 2, Angular 4, Angular 6 etc.</p>
                            </div>
                        </Col>
                        <Col md={4} className="mt-3 mt-md-5">
                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel} alt="WordPress" className="w-100 shadow" />

                                <h3 className='fw-bold h2'>WordPress</h3>
                                <p>We have a team of proficient Wordpress Developers from India. Our expert Wordpress developers help clients to develop purely reliable and scalable WordPress websites by using at the best market prices and within a short time. Try a user-friendly platform to make your website.</p>
                            </div>
                        </Col>                        <Col md={4} className="mt-3 mt-md-5">
                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel1} alt="Codeigniters" className="w-100 shadow" />

                                <h3 className='fw-bold h2'>Codeigniters</h3>
                                <p>Being one of the best CodeIgniter developers we offer quality and innovative web solutions in CodeIgniter PHP Framework. Our developers create fully functional websites and apps. All of our developers are highly skilled to work on all the latest languages.</p>
                            </div>
                        </Col>
                        <Col md={4} className="mt-3 mt-md-5">
                            <div className='text-md-start text-center content'>
                                <img src={expertiseLaravel} alt="Android" className="w-100 shadow" />
                                <h3 className='fw-bold h2'>Android</h3>
                                <p>Android’s market is very huge and people focus on getting applications based on Android. We have expert android developers at Suave Creators who can customize the applications according to the requirements.</p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </React.Fragment>

    );

}
export default ExpertiseServicesData;