import React from 'react';
import { Image } from 'react-bootstrap';
class UserImg extends React.Component {
    render() {
      const { image, id, alt} = this.props;
    
      return (
          <React.Fragment>
            <Image type="image" src= {image} className={`users user${id}`} alt={alt} />
          </React.Fragment>
      )
    }
  }
  export default UserImg;