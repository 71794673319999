import React, { Component } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import leftimg from '../../../assets/images/ourApproach.jpg'

class LefttoRight extends Component {
    render() { 
        return (
            <React.Fragment>
                <Container className='pb-5 strategy-section mt-4 mt-md-0' >
                    <Row className='justify-content-center text-md-start text-center align-items-center pb-5'>
                        <Col lg={5} md={6} data-aos="fade-right" >
                            <div className='content p-md-3 pb-md-5'>
                            <h3 className='fw-bold'>Our Approach </h3>
                                <p>We have a team of mindful & crazy people who love to explore customer-centric solutions for designing & developing websites. We aim to know what the client wants  & add value to it with some useful & extra efforts to deliver a high quality service to them. </p>
                            </div>
                        </Col> 
                        <Col lg={5} md={6} className="ps-md-0" data-aos="fade-left">
                            <div className='content pb-md-5'>
                            <Image src={leftimg} className='w-100 height-img' alt='Our approach' />
                            </div>
                        </Col>               
                    </Row>
                </Container>
            </React.Fragment>
);
}
}
export default LefttoRight;