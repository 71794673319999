import "./style.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./route";
import Header from "./Components/Header/Header";
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes />
      <Footer />
    </Router>
  );
}
export default App;
