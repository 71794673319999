import user1 from '../../assets/images/review-user-img.png'
import user2 from '../../assets/images/review-user-img2.png'
import user3 from '../../assets/images/review-user-img3.png'
import user4 from '../../assets/images/review-user-img4.png'
import user5 from '../../assets/images/review-user-img5.png'


const ReviewData = [
    {

        "id":"1",
        "image": user1,
        "title": "Laravel App",
        "text": "Great working with the team",
        "author":"Rakesh Arora",
        
    },
    {
        "id":"2",
        "image": user2,
        "title": "Codeigniter App",
        "text": "Clean and neat work done by the suave creators",
        "author":"Saurabh",

    }, 
    {
        "id":"3",
        "image": user4,
        "title": "PHP",
        "text": "Amazing person to work with, good attitude, understands problems easily and provides right solution.",
        "author":"Adnyesh",
    }, 
    {
        "id":"4",
        "image": user3,
        "title": "Wordpress",
        "text": "Excellent, working with suave creators was great.  Thanks to their knowledge and determination our website looks great and functions really good.",
        "author":"Justine",
    }, 
    {
        "id":"5",
        "image": user5,
        "title": "React",
        "text": "Putting together a website is a task, finding the right individual or company is even more complex. Thanks to suave creators who made it possible",
        "author":"James",
    },
  
  
]
export default ReviewData;