import React from 'react';
class ReviewCard extends React.Component {
    render() {
      return (
        <div className={`card-body hover-card hover-card${this.props.id}`}>
          <h5 className='my-3'>{this.props.title}</h5>
          <p className="body-content">{this.props.text}</p>
          <div className='rating'>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star checked"></span>
            <span className="fa fa-star"></span>
            <span className="fa fa-star"></span>
          </div>
          <h6 className='meta-data'>{this.props.name}</h6>
        </div>
      )
    }
  }
export default ReviewCard;