import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import authorimg from "../../assets/images/author.webp";
import { getSingleBlog } from "../../services/contact";
import "./SingleBlogContent.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";

export default function SingleBlogContent() {
  const params = useParams();
  const [detail, setDetails] = useState({});

  useEffect(() => {
    callBack(params.post_name);
  }, [params]);

  const callBack = (id) => {
    getSingleBlog(id)
      .then((response) => {
        const { status, data } = response.data.data;
        if (status === 200) {
          setDetails(data);
        }
      })
      .catch(() => {});
  };
  return (
    <React.Fragment>
     {detail && (Object.keys(detail).length > 0) && (
        <>
          <Helmet>
            <title>
              Best Rated Website Development Company - Suave Creators
            </title>
            <meta name="keywords" content={detail.keywords} />
            <meta name="description" content={detail.description} />

            <meta
              property="og:title"
              content="Best Rated Website Development Company - Suave Creators"
            />
            <meta
              property="og:image"
              content="https://www.suavecreators.com/static/media/Banner-img.447d83b18cdec70a4344.png"
            />
            <meta property="og:url" content="https://www.suavecreators.com/" />
            <meta property="og:description" content={detail.description} />
            <meta property="og:site_name" content="Suave creators" />
            <meta name="robots" content="index,follow" />
          </Helmet>

          <section className="BlogPageClass pb-md-5">
            <Container>
              <Row className="justify-content-center">
                <Col lg={10} md={12} sm={9} xs={11}>
                  <img
                    src={detail.featured_image}
                    alt={detail.alt}
                    className="w-100 mt-md-5 mt-4 mb-md-5 mb-4 blog-detail-img"
                  />
                </Col>
                <Col lg={10} md={12} sm={9} xs={11}>
                  <h1 className="display-4">{detail.post_title}</h1>
                  <p className="author-info">
                    <span>
                      <img src={authorimg} alt="author " />
                    </span>
                    <span>
                      {detail.author_name}-{" "}
                      {moment(detail.post_modified).format("LL")}
                    </span>
                  </p>
                  <p
                    dangerouslySetInnerHTML={{ __html: detail.post_content }}
                  ></p>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </React.Fragment>
  );
}
