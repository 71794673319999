import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ContactCTA.css'
 
function ContactCTA(props) {
    return ( 
        <React.Fragment>
            <section id='ContactCTA' className='py-5'>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12}  className="text-center">
                            <h2 className='purpletxt fw-bold'>Call: {props.phNumber}</h2>
                            <h5 className="fw-bolder">{props.contactSubtxt}</h5>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
     );
}

export default ContactCTA;