import React from 'react';
import Button from '../Button/Button';
import './CardBody.css'
class CardBody extends React.Component {
    render() {
      return (
        <div className="card-body" >
          <h4 className='my-4 font-size-1-25rem font-weight-bolder'>{this.props.title}</h4>
          <h6 className='meta-data'>{this.props.name}</h6>
          <p className="body-content">{this.props.text}</p>
          <Button btnhref={this.props.btnLink}  btn_text={this.props.btnText} btnclassName="learn-more"/>
        </div>
      )
    }
  }
export default CardBody;