import React from "react";
import { Card } from "react-bootstrap";
import BlogCardBody from "../BlogCardBody/BlogCardBody";
import CardHeader from "../CardHeader/CardHeader";
import "./BlogCard.css";

class BlogCard extends React.Component {
  render() {
    return (
      <>
        <Card className={` ${this.props.cardClass}`}>
          <CardHeader
            image={this.props.details.featured_image}
            alt={this.props.details.alt}
            cardImgClass="w-100"
          />
          {}
          <BlogCardBody
            post_name={this.props.details.post_name}
            ID={this.props.details.ID}
            title={this.props.details.post_title}
            name={this.props.details.name}
            text={this.props.details.post_content}
            btnlink={this.props.details.btnLink}
            btnText={this.props.details.btnText}
          />
        </Card>
      </>
    );
  }
}

export default BlogCard;
