import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from '../ContactForm/ContactForm';
import Faq from '../Faq/Faq';
import './FormSection.css'

class FormSection extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <section id="form-sec" className='bg-light py-5'>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <ContactForm />
                            </Col>
                            <Col md={6}>
                                 <Faq/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
 
export default FormSection;