import React, { Component } from 'react';
import SingleBlogContent from "../../Components/SingleBlogContent/SingleBlogContent"

class SingleBlogPage extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <SingleBlogContent />
            </React.Fragment>
               
            );
        }
    }
export default SingleBlogPage;