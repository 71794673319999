import React from 'react';
import { Card } from 'react-bootstrap';
import CardBody from '../CardBody/CardBody';
import CardHeader from '../CardHeader/CardHeader';
import './SecCard.css'
class SecCard extends React.Component {
  render() {

    return (
      <div className='col mt-md-4' data-aos="flip-right" data-aos-duration="3000">
        <Card className={`mt-5 ${this.props.cardClass}`}>
          <CardHeader image={this.props.details.image} alt={this.props.details.alt} number={this.props.details.number} cardImgClass="card-icon" />
          <CardBody title={this.props.details.title} name={this.props.details.name} text={this.props.details.text} btnlink={this.props.details.btnLink} btnText={this.props.details.btnText} />
        </Card>
      </div>
    )
  }
}

export default SecCard;