import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import "./CallToAction.css"

class CallToAction extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <section id="subscribe-sec">
                    <Container>
                        <Row className='justify-content-between text-md-start text-center'>
                            <Col md={7} lg={7}>
                                <h4 className='text-white'>Come and build together a better business with Suave Creators</h4>
                            </Col>
                            <Col md={5} lg={4} className="mt-3 mt-md-0">
                                <Link to={"/contact-us"}><Button btnclassName="blue-bg" btn_text="Contact Us" /></Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default CallToAction;