import React, { Component } from 'react';
import siteLogo from '../../assets/images/BlueLogo.png';

class Logo extends Component {
    state = {  }    
    render() { 
        return (
            <React.Fragment>
                <img src={siteLogo} alt="SauveCreator" className="Logo"/>
            </React.Fragment>
        );
    }
}
 
export default Logo;